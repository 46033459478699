import compose from "lodash/fp/compose";
import { domReady } from "../../utils";

const toggleContentListeners = () => {
  const menuItems = document.querySelectorAll("[data-toggle-content]");

  if (menuItems) {
    menuItems.forEach((menuItem) => {
      menuItem.addEventListener("click", function (event) {
        event.preventDefault();

        const contentId = menuItem.getAttribute("data-toggle-content");
        showContent(contentId);
      });
    });
  }

  const backToMenuButtons = document.querySelectorAll("[data-show-menu]");
  if (backToMenuButtons) {
    backToMenuButtons.forEach((button) => {
      button.addEventListener("click", function (event) {
        event.preventDefault();
        showMenu();
      });
    });
  }
};

function showContent(contentId) {
  document.getElementById("menu").style.display = "none";
  document.getElementById("content-area").style.display = "block";

  var contents = document.getElementsByClassName("tab-pane");
  for (var i = 0; i < contents.length; i++) {
    contents[i].style.display = "none";
  }

  document.getElementById(contentId).style.display = "block";
}

function showMenu() {
  document.getElementById("content-area").style.display = "none";
  document.getElementById("menu").style.display = "block";
}

export default domReady(compose(toggleContentListeners));
