"use strict";
import ApexCharts from "apexcharts";

document.addEventListener("DOMContentLoaded", function () {
  setTimeout(function () {
    floatchart();
  }, 500);
});
function floatchart() {
  (function () {
    var widgetOptions = {
      chart: {
        type: "bar",
        height: 50,
        width: 80,
        sparkline: { enabled: true },
      },
      colors: ["currentColor"],
      plotOptions: { bar: { borderRadius: 1, columnWidth: "5px" } },
      xaxis: { tickAmount: 13, crosshairs: { width: 1 } },
      tooltip: {
        fixed: { enabled: false },
        x: { show: true },
        y: {
          title: {
            formatter: function (seriesName) {
              return "";
            },
          },
        },
        marker: { show: false },
      },
    };

    const paidOrdersChart = document.querySelector("#paid-orders-chart");
    if (paidOrdersChart) {
      var chart = new ApexCharts(paidOrdersChart, {
        ...widgetOptions,
        series: [
          {
            data: Object.values(
              JSON.parse(paidOrdersChart.dataset.array.replace(/=>/g, ":"))
            ),
          },
        ],
        xaxis: {
          ...widgetOptions.xaxis,
          categories: Object.keys(
            JSON.parse(paidOrdersChart.dataset.array.replace(/=>/g, ":"))
          ),
        },
      });
      chart.render();
    }

    const pendingOrdersChart = document.querySelector("#pending-orders-chart");
    if (pendingOrdersChart) {
      var chart = new ApexCharts(pendingOrdersChart, {
        ...widgetOptions,
        series: [
          {
            data: Object.values(
              JSON.parse(pendingOrdersChart.dataset.array.replace(/=>/g, ":"))
            ),
          },
        ],
        xaxis: {
          ...widgetOptions.xaxis,
          categories: Object.keys(
            JSON.parse(pendingOrdersChart.dataset.array.replace(/=>/g, ":"))
          ),
        },
      });
      chart.render();
    }

    const canceledOrdersChart = document.querySelector(
      "#canceled-orders-chart"
    );
    if (canceledOrdersChart) {
      var chart = new ApexCharts(canceledOrdersChart, {
        ...widgetOptions,
        series: [
          {
            data: Object.values(
              JSON.parse(canceledOrdersChart.dataset.array.replace(/=>/g, ":"))
            ),
          },
        ],
        xaxis: {
          ...widgetOptions.xaxis,
          categories: Object.keys(
            JSON.parse(canceledOrdersChart.dataset.array.replace(/=>/g, ":"))
          ),
        },
      });
      chart.render();
    }

    const notApprovedOrdersChart = document.querySelector(
      "#not-approved-orders-chart"
    );
    if (notApprovedOrdersChart) {
      var chart = new ApexCharts(notApprovedOrdersChart, {
        ...widgetOptions,
        series: [
          {
            data: Object.values(
              JSON.parse(
                notApprovedOrdersChart.dataset.array.replace(/=>/g, ":")
              )
            ),
          },
        ],
        xaxis: {
          ...widgetOptions.xaxis,
          categories: Object.keys(
            JSON.parse(notApprovedOrdersChart.dataset.array.replace(/=>/g, ":"))
          ),
        },
      });
      chart.render();
    }

    const columnsGraph = document.querySelector("#columns-graph");
    if (columnsGraph) {
      var columnsOptions = {
        chart: {
          height: 485,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "40%",
            borderRadius: 4,
            borderRadiusApplication: "end",
          },
        },
        legend: {
          show: true,
          position: "top",
          horizontalAlign: "left",
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#6943D5", "#0AA3D4"],
        stroke: {
          show: true,
          width: 3,
          colors: ["transparent"],
        },
        fill: {
          colors: ["#6943D5", "#0AA3D4"],
        },
        grid: {
          show: false,
        },
        series: [
          {
            name: "Total de Vendas",
            data: Object.values(
              JSON.parse(columnsGraph.dataset.sellers.replace(/=>/g, ":"))
            ),
          },
          {
            name: "Pedidos Pagos",
            data: Object.values(
              JSON.parse(columnsGraph.dataset.paid.replace(/=>/g, ":"))
            ),
          },
        ],
        yaxis: {
          tickAmount: 6,
          labels: {
            formatter: function (val) {
              return val;
            },
          },
        },
        xaxis: {
          categories: Object.keys(
            JSON.parse(columnsGraph.dataset.sellers.replace(/=>/g, ":"))
          ),
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      };
      var chart = new ApexCharts(columnsGraph, columnsOptions);
      chart.render();
    }

    const donutGraph = document.querySelector("#donut-graph");
    if (donutGraph) {
      var donutOptions = {
        chart: {
          height: 335,
          type: "donut",
        },
        series: JSON.parse(donutGraph.dataset.array),
        colors: ["#22C48E", "#0177E6", "#FAAC39"],
        labels: ["Cartão", "Pix", "Boleto"],
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              size: "65%",
              labels: {
                show: true,
                name: {
                  show: true,
                  fontFamily: "Inter var",
                },
                value: {
                  show: true,
                  color: "#154D62",
                  fontWeight: "600",
                  fontFamily: "Inter var",
                  formatter: function (val) {
                    return Number(val).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    });
                  },
                },
                total: {
                  show: true,
                  label: "Total",
                  color: "#5B6B79",
                  fontFamily: "Inter var",
                  formatter: function (w) {
                    const totalValue = w.globals.seriesTotals.reduce(
                      (a, b) => a + b,
                      0
                    );
                    return totalValue.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    });
                  },
                },
              },
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 575,
            options: {
              chart: {
                height: 250,
              },
              plotOptions: {
                pie: {
                  donut: {
                    size: "65%",
                    labels: {
                      show: false,
                    },
                  },
                },
              },
            },
          },
        ],
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
            },
          },
        },
      };
      var chart = new ApexCharts(donutGraph, donutOptions);
      chart.render();
    }

    const pieGraph = document.querySelector("#pie-graph");
    if (pieGraph) {
      const seriesData = JSON.parse(pieGraph.dataset.array);
      const totalSeries = seriesData.reduce((a, b) => a + b, 0);

      var pieOptions = {
        chart: {
          height: 335,
          type: "pie",
        },
        labels: ["Cartão", "Pix", "Boleto"],
        series: JSON.parse(pieGraph.dataset.array),
        colors: ["#22C48E", "#0177E6", "#FAAC39"],
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: true,
          dropShadow: {
            enabled: false,
          },
          formatter: function (val) {
            return val.toFixed(2) + "%";
          },
        },
        responsive: [
          {
            breakpoint: 575,
            options: {
              chart: {
                height: 250,
              },
              dataLabels: {
                enabled: false,
              },
            },
          },
        ],
        tooltip: {
          y: {
            formatter: function (val) {
              let percentage = (val / totalSeries) * 100;
              return percentage.toFixed(2) + "%";
            },
          },
        },
      };
      var chart = new ApexCharts(pieGraph, pieOptions);
      chart.render();
    }
  })();
}
