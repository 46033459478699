import compose from "lodash/fp/compose";
import { domReady } from "../../utils";

const togglePassword = () => {
  const targetTogglePassword = document.querySelectorAll("#togglePasswordBtn");
  const icon = document.querySelector("#togglePasswordBtn use");

  if (targetTogglePassword) {
    targetTogglePassword.forEach((target) => {
      target.addEventListener("click", function (event) {
        event.preventDefault();

        let passwordField = target.parentElement.querySelector("input");
        let icon = target.parentElement.querySelector("#togglePasswordBtn use");

        if (passwordField.getAttribute("type") == "password") {
          passwordField.setAttribute("type", "text");
          icon.setAttribute("xlink:href", "#vuesax-outline-eye");
        } else {
          passwordField.setAttribute("type", "password");
          icon.setAttribute("xlink:href", "#vuesax-outline-eye-slash");
        }
      });
    });
  }
};

export default domReady(compose(togglePassword));
